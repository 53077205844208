<template>
	<div class="invite-download-page">
		<van-image
			fit="contain"
			:src="resourceURL + 'images/invite/logo.png'"
			class="logo"
		/>

		<van-row class="buttons">
			<van-col span="24" style="margin-bottom:15px;">
				<van-button :icon="resourceURL + 'images/invite/icon_ios.png'" type="default" block round color="#FE6D01" plain size="large" @click="iosDownload">Iphone 下载</van-button>
			</van-col>
			<van-col span="24" style="margin-bottom:15px;">
				<van-button :icon="resourceURL + 'images/invite/icon_android.png'" type="default" block round color="#FE6D01" plain size="large" @click="androidDownload">Android 下载</van-button>
			</van-col>
			<!--
			<van-col span="24">
				<van-button :icon="resourceURL + 'images/invite/icon_android.png'" type="default" block round color="#FE6D01" plain size="large" @click="googlePlay">Google Play</van-button>
			</van-col>
			-->
		</van-row>

		<van-overlay :show="isWeiXin">
			<div class="wrapper">
				<div class="weixin-browser"></div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	export default {
		name: 'invite_download',
		data() {
			return {
				resourceURL: this.$axios.defaults.resourceURL,
				
				isWeiXin: false,
			}
		},

		mounted() {
			document.title = '华博证券APP下载'

			
			//window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
			var ua = window.navigator.userAgent.toLowerCase();
			//alert(JSON.stringify(ua))
			//通过正则表达式匹配ua中是否含有MicroMessenger字符串
			if(ua.match(/MicroMessenger/i) == 'micromessenger'){
				this.isWeiXin = true
			} else{
				this.isWeiXin = false

				this.loadURL()
			}
		},
		
		methods: {

			iosDownload(){
				window.location.href= 'https://apps.apple.com/cn/app/%E5%8D%8E%E5%8D%9A%E8%AF%81%E5%88%B8/id1559172378'
			},
			
			androidDownload() {
				window.location.href= this.$axios.defaults.baseURL + 'download_download/android'			
			},

			googlePlay() {
				window.location.href= 'https://play.google.com/store/apps/details?id=com.app.android.bravo.ipo.hua.bo'
			},

			loadURL() {
				var u = window.navigator.userAgent;
				var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
				// var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

				if (isAndroid) {
					window.location.href = 'huabo://huaboandroidhost:1024/main'
				}

				// if(isIOS){
				// 	window.location.href = 'huabo://';
				// }
			}
		},

	}
</script>